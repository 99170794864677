const MetaData = {
    applicationName: "AWA App",
    referrer: "referrer",
    authors: "Md Arif",
    canonical: "https://assistwebapp.com",
    title: "Assist Web App | Stay Ahead with AssistWebApp: Education, Programming Language & Tech.",
    keywords : "Assist Web App, assistwebapp.in, assistwebapp.com, Programming Language, learn to code, free coding tutorial, programming basics, programming for beginners, programming languages, best programming languages to learn, in-demand programming skills, programming jobs, programming career, programming bootcamp, online programming courses, javascript tutorial, javascript basics, javascript for beginners, javascript functions, javascript objects, html tutorial, html basics, html for beginners, html elements, html attributes, html tags, html forms, html tables, html CSS, html javascript, html SEO, html coding challenges, css tutorial, css basics, css for beginners, css selectors, css properties, css layout, css responsive design, css animations, css transitions, css best practices, css job interview questions, css coding challenges, react.js tutorial, react.js basics, react.js for beginners, react.js components, react.js state, react.js props, react.js hooks, react.js router, react.js redux, react.js best practices, react.js job interview questions, react.js coding challenges, web design tutorial, web design basics, web design for beginners, web design principles, web design elements, web design software, web design trends, web design best practices, web design job interview questions, web design coding challenges, web developer tutorial, web developer basics, web developer for beginners, web developer skills, web developer tools, web development process, web development frameworks, web development libraries, web developer best practices, web developer job interview questions, web developer coding challenges, javascript events, javascript DOM, javascript frameworks, javascript libraries, javascript best practices, javascript job interview questions, javascript coding challenges",
    dis : "Stay informed and stay ahead with AssistWebApp. Explore programming languages, and technology. Dive into a treasure trove of information that empowers you to make informed decisions. AssistWebApp is your go-to source for staying updated with the vibrant nation's evolving landscape.  broaden your educational horizons, and stay up-to-date with the ever-evolving world of programming languages and technology. Join us and embark on an enriching journey of knowledge, progress, and innovation.",
};

export default MetaData;