import Home from "./page/Home";

function App() {
  return (
    <>
        <Home />
    </>
  );
}
export default App;
