export default function Footer(){
    return(
        <>
          <footer className="bg-body-footer py-2">
            <div className="container">
                <p className="mb-0 text-center text-black">
                    <a>Copyright © 2024 AWA. All rights reserved</a>
                </p>
            </div>
          </footer>
        </>
    )
}