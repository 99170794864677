import Header from "../../body/Header";
import Footer from "../../body/Footer";
import { TypeAnimation } from 'react-type-animation';
import { web_url, Book_url } from "../../body/Links"
import './HomePage.css';
import App from "./BannerAnimation";

export default function HomePage(){
    return(
        <>
           <Header />
              <main>
                 <section className="bg_banner d-flex align-items-center">
                   <div className="container">
                    <div className="row d-lg-flex justify-content-center align-items-center w-lg-100">
                           <div className="col-lg-10 pt-5">
                                 <div className="main_banner">
                                    <div className="text_area">
                                        <h1 className="mb-0">Assist Web App Gold Coast</h1>
                                        <div className="animation_text">
                                             <span>Together we</span> 
                                             <div className="animation_text_main">
                                                <TypeAnimation
                                                    sequence={['Build', 2000, 'Craft', 2000, 'Grow', 2000]}
                                                    style={{ fontSize: '2em' }}
                                                    repeat={Infinity}
                                                    />
                                             </div>
                                        </div>
                                        <a href={Book_url} target="_blank" className="btn link btn-primary">Start a Project</a>
                                    </div>
                                    <div className="svg_hand">
                                        <svg className="shape-hand" width="243" height="662" viewBox="0 0 243 662" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M191.77 47.8401C197.806 47.8401 203.595 50.238 207.864 54.5064C212.132 58.7747 214.53 64.5638 214.53 70.6001V171.24H169V70.6001C169 67.6104 169.589 64.6499 170.733 61.8879C171.878 59.1259 173.555 56.6164 175.67 54.5028C177.784 52.3892 180.295 50.7129 183.057 49.5697C185.82 48.4265 188.78 47.8388 191.77 47.8401V47.8401Z" fill="#FFB5D1"></path>
                                            <path d="M146.25 47.8401C152.285 47.8427 158.071 50.2418 162.338 54.5099C166.604 58.7779 169 64.5655 169 70.6001V171.24H123.49V70.6001C123.49 64.5638 125.888 58.7747 130.156 54.5063C134.425 50.238 140.214 47.8401 146.25 47.8401V47.8401Z" fill="#FFB5D1"></path>
                                            <path d="M100.72 0.439941C106.756 0.439941 112.545 2.83786 116.814 7.10619C121.082 11.3745 123.48 17.1636 123.48 23.1999V171.2H78V23.1999C78 17.1705 80.3924 11.3875 84.6521 7.12032C88.9118 2.85314 94.6906 0.450538 100.72 0.439941V0.439941Z" fill="#FFB5D1"></path>
                                            <path d="M83.7297 333.31V261.54C83.7404 243.959 78.5187 226.773 68.7297 212.17L9.84971 124.4C8.12301 121.829 6.93105 118.937 6.34495 115.896C5.75885 112.855 5.79059 109.727 6.43809 106.699C7.08559 103.67 8.33564 100.803 10.114 98.2674C11.8924 95.7319 14.1626 93.5801 16.7897 91.94V91.94C21.9261 88.7305 28.1173 87.6657 34.0309 88.9748C39.9444 90.284 45.1078 93.8624 48.4096 98.94L77.9697 144.54L214.53 171.25V333.31H83.7297Z" fill="#FFB5D1"></path>
                                            <circle cx="101" cy="26" r="13" stroke="black" stroke-width="4"></circle>
                                            <rect x="70" y="281" width="157" height="373" rx="8" fill="#3182FF"></rect>
                                            <circle cx="89" cy="316" r="7" stroke="black" stroke-width="4"></circle>
                                            <path d="M40.1547 106.282C36.9744 100.474 29.6878 98.3439 23.8797 101.524C18.0715 104.705 15.9413 111.991 19.1216 117.799" stroke="black" stroke-width="4" stroke-linecap="round"></path>
                                            <line x1="124.5" y1="70" x2="124.5" y2="125" stroke="black" stroke-width="4" stroke-linecap="round"></line>
                                            <line x1="169.5" y1="70" x2="169.5" y2="125" stroke="black" stroke-width="4" stroke-linecap="round"></line>
                                            <path d="M215 348.369L211.705 345.15C207.729 341.265 201.306 341.512 197.64 345.691L197.369 346C193.65 350.239 187.051 350.239 183.333 346V346C179.614 341.761 173.015 341.761 169.296 346V346C165.578 350.239 158.979 350.239 155.26 346V346C151.541 341.761 144.942 341.761 141.224 346V346C137.505 350.239 130.906 350.239 127.187 346V346C123.469 341.761 116.87 341.761 113.151 346V346C109.432 350.239 102.834 350.239 99.1149 346V346C95.3962 341.761 88.7972 341.761 85.0785 346L83 348.369" stroke="black" stroke-width="4" stroke-linecap="round"></path>
                                        </svg>
                                    </div>
                                 </div>
                           </div>
                       </div>
                    </div>
                 </section>
                 <App />
                 <section className="bg_about py-5">
                    <div className="container">
                        <div className="row">
                          <div className="col-lg-6">
                              <img src={`${web_url}/img/live-website.png`} className="img-fluid" alt="about assist web app" />
                          </div>
                          <div className="col-lg-6 d-flex align-items-center">
                            <div className='about_text'>
                                <h2><span><b className="about_bold">About Us </b>- Assist Web App</span></h2>
                                <p>Welcome to Assist Web App, where our passion for pioneering web applications meets a commitment to excellence. At the forefront of our mission is a dedication to innovation and user-centric design. Armed with expertise in cutting-edge technologies such as React.js, Next.js, Node.js, Express.js, and MongoDB, we specialize in crafting robust and scalable solutions that redefine digital experiences. From dynamic web applications to responsive mobile interfaces, our multi-platform proficiency ensures that your vision is seamlessly translated into a reality that surpasses industry standards.</p>
                                <p>Our collaborative approach sets us apart, as we prioritize transparent communication and work hand-in-hand with clients to bring their unique ideas to fruition. With a foundation built on the latest technologies, a focus on user satisfaction, and an unwavering commitment to scalability, Assist Web App is your trusted partner in creating exceptional digital solutions. Join us as we embark on a journey to elevate your online presence and redefine what's possible in the ever-evolving landscape of web and mobile applications.</p>
                            </div> 
                          </div>
                        </div>
                    </div>
                 </section>
                 
                 <section className="bg_service py-5">
                    <div className="container">
                        <div className="row pb-3">
                          <div className="col-lg-12">
                               <h2 className="text-center">What we do</h2>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="row">
                               <div className="col-lg-12">
                                    <div className="service_items img_move_one">
                                        <div className="move_text_area">
                                            <img src={`${web_url}/icon/web-page.webp`} width='100px' className="img-fluid" alt="about assist web app" />
                                            <h3 className="text-white pt-3">Web Page</h3>
                                            <p className="text-white">We are passionate about transforming ideas into captivating online experiences. With a dedicated team of creative minds and technical experts, we specialize in web design and development that goes beyond the ordinary.</p>
                                        </div>
                                        <div className="img">
                                            <img src={`${web_url}/svg/img_move_01.svg`} className="img-fluid img_move" />
                                        </div>
                                    </div>
                               </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="row">
                               <div className="col-lg-12">
                                    <div className="service_items img_move_two">
                                        <div className="move_text_area">
                                            <img src={`${web_url}/icon/web_appliaction.webp`} width='100px' className="img-fluid" alt="about assist web app" />
                                            <h3 className="pt-3">Web Application</h3>
                                            <p>Crafting dynamic web solutions with React, MongoDB, Node.js, and Express.js. We seamlessly integrate technologies, delivering powerful web applications that elevate user experiences. Your vision, our expertise – let's build together.</p>
                                        </div>
                                        <div className="img">
                                            <img src={`${web_url}/svg/img_move_02.svg`} className="img-fluid img_move" />
                                        </div>
                                    </div>
                               </div>
                               <div className="col-lg-12">
                                    <div className="service_items img_move_three">
                                        <div className="move_text_area">
                                            <img src={`${web_url}/icon/modile_app.webp`} width='100px' className="img-fluid" alt="about assist web app" />
                                            <h3 className="pt-3">Mobile Application</h3>
                                            <p>Revolutionize with React Native. We craft seamless, cross-platform mobile applications that blend efficiency and elegance. Elevate user experiences—your vision, our code. Let's bring your app to life.</p>
                                        </div>
                                        <div className="img">
                                            <img src={`${web_url}/svg/img_move_03.svg`} className="img-fluid img_move2" />
                                        </div>
                                    </div>
                               </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="row">
                               <div className="col-lg-12">
                                    <div className="service_items img_move_four">
                                        <div className="move_text_area">
                                            <img src={`${web_url}/icon/desktop_application.webp`} width='100px' className="img-fluid" alt="about assist web app" />
                                            <h3 className="pt-3">Desktop Application</h3>
                                            <p>Crafting seamless desktop experiences with ReactJS and ElectronJS. Elevate your application with our expertise in responsive UIs and efficient performance. Transform ideas into powerful desktop solutions effortlessly. Let's build together.</p>
                                        </div>
                                        <div className="img">
                                            <img src={`${web_url}/svg/img_move_04.svg`} className="img-fluid img_move2" />
                                        </div>
                                    </div>
                               </div>
                            </div>
                          </div>
                        </div>
                    </div>
                 </section>

                 <section className="bg_faq py-5">
                    <div className="container">
                        <div className="row pb-5">
                          <div className="col-lg-12">
                               <h2 className="text-center">FAQ's</h2>
                          </div>
                        </div>
                        <div className="accordion accordion-flush" id="accordionFlushExample">
                          <div className="row">
                            <div className="col-lg-6">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                       What technologies does Assist Web App specialize in?
                                    </button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                     <div className="accordion-body">At Assist Web App, we excel in modern web technologies such as React.js, Next.js, Node.js, Express.js, and MongoDB. These cutting-edge tools form the backbone of our development process, ensuring that we deliver high-performance and scalable solutions tailored to your specific needs.</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                      How does Assist Web App prioritize user experience in its design philosophy?
                                    </button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                      <div className="accordion-body">User-centric design is integral to our approach. We not only focus on creating visually appealing interfaces but also prioritize intuitive navigation, ensuring a seamless and enjoyable experience for users. Our commitment to user satisfaction is embedded in every aspect of our design process.</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                       Can Assist Web App develop applications for multiple platforms?
                                    </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                      <div className="accordion-body">Absolutely! Whether you need a web application, desktop application, or mobile application, Assist Web App has the expertise to meet your requirements across various platforms. Our versatile solutions cater to the diverse needs of your business.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq3" aria-expanded="false" aria-controls="flush-collapseOne">
                                       How does Assist Web App ensure the scalability of its solutions?
                                    </button>
                                    </h2>
                                    <div id="faq3" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                      <div className="accordion-body">We understand that business needs evolve, and our solutions are designed with scalability in mind. Our applications are equipped to grow seamlessly with your business, ensuring that they remain robust and adaptable to changing demands.</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq4" aria-expanded="false" aria-controls="flush-collapseTwo">
                                       What is the collaborative approach of Assist Web App in working with clients?
                                    </button>
                                    </h2>
                                    <div id="faq4" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                      <div className="accordion-body">We believe in transparent communication and collaboration. We work closely with our clients, valuing their input and vision throughout the development process. This collaborative approach ensures that the final product aligns perfectly with the client's expectations.</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq5" aria-expanded="false" aria-controls="flush-collapseThree">
                                      How can businesses benefit from partnering with Assist Web App?
                                    </button>
                                    </h2>
                                    <div id="faq5" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                      <div className="accordion-body">By choosing Assist Web App, businesses gain access to a team of passionate professionals committed to crafting exceptional digital solutions. From utilizing the latest technologies to prioritizing user satisfaction and scalability, we empower businesses to elevate their online presence and redefine possibilities in the digital realm.</div>
                                    </div>
                                </div>
                               </div>
                            </div>
                        </div>
                    </div>
                 </section>

              </main>
           <Footer />
        </>
    )
}