const site_url = "https://assistwebapp.com";
const web_url = "https://assistwebapp.com";
const Main_Api = 'https://api.indiaanalysis.in';

const tools_site = 'https://tools.assistwebapp.com';

// const site_url = "http://localhost:3000";
// const web_url = "http://localhost:3000";
// const Main_Api = 'http://localhost:3000';

const default_img = 'awa_logo_to.jpg';

const loding = 'loading.gif';

const img_url = "https://app.indiaanalysis.in/storage/app/public/";
const Book_url = 'https://forms.gle/q8giju4DXTRPcPuF8';

const Coming_app = 'mobile-app-coming-soon.jpg';
const app_logo = 'AssistWebApp_main.png';
const favCon = 'logo_icon.png';

const App_img = {
    awa_cal: `${web_url}/app/awa_calculator.webp`
}

const API_URL = {
    Category: `${Main_Api}/category`,
    Technology: `${Main_Api}/category/technology`,
    Javascript: `${Main_Api}/category/javascript`,
    React: `${Main_Api}/category/react-js`,
};

export default site_url;
export {
        API_URL, img_url, Main_Api, web_url, Book_url, default_img, Coming_app, app_logo, favCon, loding, tools_site,
        App_img
       };