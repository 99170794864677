import { Main_Api, web_url, Book_url, tools_site } from "./Links";
import Sticky from 'react-sticky-el';


export default function Header(){
    return(
        <>
          <header>
            <Sticky boundaryElement=".block" className='new_stk' hideOnBoundaryHit={false}>
                <nav className="navbar navbar-expand-lg bg-body-me">
                    <div className="container  d-flex justify-content-center">
                    <div className="row">
                        <div className="col-lg-2 col-6">
                            <a className="navbar-brand" href="/" >
                                <img src="/AssistWebApp.png" className="img-fluid logo_img" />
                            </a>
                        </div>
                        <div className="col-lg-10 col-6 nav_links d-flex justify-content-end align-items-center">
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav me-auto mb-2 w-lg-100 mb-lg-0 d-lg-flex justify-content-lg-end align-items-lg-center">
                                    <li className="nav-item">
                                       <a className="link blinking-text" href={Book_url} target="_blank">Start a Project</a>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="link nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false" href={"/"}> 
                                           Tool<small><small>s</small></small>
                                        </a>
                                        <ul className="dropdown-menu p-0">
                                            <li className="d-grid">
                                                <a className="link m-0" href={`https://design.assistwebapp.com/?page=editor`} target="_blank">Photo Design</a>
                                                <a className="link m-0" href={`${tools_site}/jpg-to-webp`}>Jpg to Webp</a>
                                                <a className="link m-0" href={`${tools_site}/png-to-webp`}>Png to Webp</a>
                                                <a className="link m-0" href={`${tools_site}/qr-code-generator-online`}>Qr Code Generator</a>
                                                <a className="link m-0" href={`${tools_site}/youtube-mp3`}>YouTube to MP3</a>
                                                <a className="link m-0" href={`${tools_site}/`}>Other Tools</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <a className="link" href={`${web_url}/app`}>Mobile App<small><small>s</small></small></a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="link" href={`${web_url}/blog`}>Blog<small><small>s</small></small></a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="link" href={Book_url} target="_blank">Get in Touch</a>
                                    </li>
                                </ul>
                            </div>
                            <a className='search_icon' href={`/search`}><i className="bi bi-search"></i></a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                        </div>
                    </div>
                </nav>
            </Sticky>
          </header>
        </>
    )
}