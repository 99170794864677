import MetaData from '../metadata';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HomePage from './HomeCom/homePage';

function Home(){
    return(
        <HelmetProvider>
        <Helmet>
            <title>{`${MetaData.title}`}</title>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta charset="UTF-8" />
            <meta name="robots" content="all" />
            <link rel="canonical" href={`${MetaData.canonical}`} />
            <meta name="keywords" content={`${MetaData.keywords}`} />
            <meta name="author" content={`${MetaData.authors}`} />
            <meta name="description" content={`${MetaData.dis}`} />
        </Helmet>
          <HomePage />
        </HelmetProvider>
    )
}

export default Home;